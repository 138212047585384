@import url(https://fonts.googleapis.com/css?family=Arvo:400,700);
/*
Modified - by Ronald Widha. dont overwrite

Theme Name: Less
Theme URI: https://lessmade.com/themes/less
Author: Jared Erickson
Author URI: https://jarederickson.com
Description: A super simple theme that can be customized using LESS variables
Version: 1.1
License: GNU General Public License

*/
/* LESS variables - Change the look of your site simply.
-------------------------------------------------------------- */
/* Reset
-------------------------------------------------------------- */
/* https://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit;
  font-size: 99%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units https://clagnut.com/blog/348/#c790 */

  -webkit-text-size-adjust: 100%;
  /* Prevents iOS text size adjust after orientation change, without disabling user zoom */

  -ms-text-size-adjust: 100%;
  /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */

}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
code,
kbd,
tt,
var {
  font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}
abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}
mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}
sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}
small {
  font-size: 75%;
}
big {
  font-size: 125%;
}
figure {
  margin: 0;
}
table {
  margin: 0 0 1.5em;
  width: 100%;
}
th {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-size: 100%;
  /* Corrects font size not being inherited in all browsers */

  margin: 0;
  /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */

  vertical-align: baseline;
  *vertical-align: middle;
  /* Improves appearance and consistency in all browsers */

}
button,
input {
  line-height: normal;
  /* Addresses FF3/4 setting line-height using !important in the UA stylesheet */

  *overflow: visible;
  /* Corrects inner spacing displayed oddly in IE6/7 */

}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid #dddddd;
  border-color: #ccc #ccc #bbb #ccc;
  border-radius: 3px;
  background: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  /* Improves usability and consistency of cursor style between image-type 'input' and others */

  -webkit-appearance: button;
  /* Corrects inability to style clickable 'input' types in iOS */

  font-size: 12px;
  font-size: 1.4rem;
  line-height: 1;
  padding: 1.12em 1.5em 1em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
button:hover,
html input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: #ccc #bbb #aaa #bbb;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02);
}
button:focus,
html input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
html input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
  border-color: #aaa #bbb #bbb #bbb;
  -webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* Addresses box sizing set to content-box in IE8/9 */

  padding: 0;
  /* Addresses excess padding in IE8/9 */

}
input[type="search"] {
  -webkit-appearance: textfield;
  /* Addresses appearance set to searchfield in S5, Chrome */

  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */

  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  /* Corrects inner padding displayed oddly in S5, Chrome on OSX */

  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */

  border: 0;
  padding: 0;
}
input[type=text],
input[type=email],
textarea {
  color: #888888;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
  color: #101010;
}
input[type=text],
input[type=email] {
  padding: 3px;
}
textarea {
  overflow: auto;
  /* Removes default vertical scrollbar in IE6/7/8/9 */

  padding: 10px;
  vertical-align: top;
  /* Improves readability and alignment in all browsers */

  width: 98%;
}
/* styles
-------------------------------------------------------------- */
body,
button,
input,
select {
  padding: 0;
  margin: 0;
  background: #ffffff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 26.64px;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 200;
}
/* typography
-------------------------------------------------------------- */
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: "Arvo", Georgia, serif;
}
h1 {
  line-height: 71.03999999999999px;
  font-size: 48px;
  font-size: 4.8rem;
}
h2 {
  font-size: 17px;
  font-size: 2rem;
}
h3 {
  font-size: 15px;
  font-size: 1.7rem;
}
h4 {
  font-size: 13px;
  font-size: 1.3rem;
}
hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}
/* Text elements */
p {
}
ul,
ol {
  margin: 0 0 1.5em 3em;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
  margin-left: 1.5em;
}
dt {
  font-weight: bold;
}
dd {
  margin: 0 1.5em 1.5em;
}
b,
strong {
  font-weight: bold;
}
dfn,
cite,
em,
i {
  font-style: italic;
}
blockquote {
  margin: 0 1.5em;
}
address {
  margin: 0 0 1.5em;
}
pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  overflow: auto;
  max-width: 100%;
}
/* Links */
a {
  color: #007998;
  -webkit-transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  -ms-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  color: rgba(0, 121, 152, 0.5);
}
/* Alignment */
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}
.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto;
}
/* Header
-------------------------------------------------------------- */
header {
  padding: 20px 0;
  background: transparent;
  margin: 20px auto;
}
header .gravatar {
  overflow: hidden;
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0;
  float: right;
}
header #brand {
  float: left;
  width: 80%;
  margin-top: 22px;
}
header #brand h1 {
  font-family: "Arvo", Georgia, serif;
  font-weight: 300;
  color: #565656;
  font-size: 2.3 rem;
  margin: 0;
  padding: 0;
}
header #brand h1 a {
  color: #565656;
  text-decoration: none;
}
header #brand h1 span {
  font-weight: 200;
  color: #888888;
  text-transform: lowercase;
}
header nav {
  float: left;
}
header nav ul {
  list-style: none;
  margin: 0;
}
header nav ul li {
  float: left;
  margin: 2px 10px 0 0;
}
header nav ul li a {
  color: #888888;
  font-size: 14px;
}

.profile-col-1 {
  margin-left: -12px;
  margin-right: 22px;
  margin-top: 15px;
  width:75%;
  float:left;
}

.profile-col-2 {
  margin-left: -12px;
  margin-top: 15px;
  width:25%;
  min-width: 120px;
  float:left;
}

.container {
  max-width: 720px;
  padding: 20px;
  margin: 0 auto;
  position: relative;
}
/* HOME */
h1.archive-heading {
  font-size:1.2em;
}
.home {
    margin-bottom: 30px;
    border-bottom: 1px solid #dddddd;
}
ul.post-list {
  margin-left: 0px;
  list-style: none;
  font-size: 0.7em;
}
ul.post-list li {
  margin-bottom: 10px;  
}
ul.post-list li h2 {
  font-size: 1.6rem;
}
ul.post-list li span.post-meta {
  font-size: 0.9em;
  color: #666666;
}
ul.post-list .card {
  margin-top: 15px;
}
.card {
  border-radius: 5px;
  padding: 10px 12px 5px 12px;

  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}
/* Posts/Articles
-------------------------------------------------------------- */
.the-content h2 {
  margin-bottom: 10px;  
}
article {
  background: transparent;
  padding: 0 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd;
}
article .title {
  color: #404040;
  line-height: 35.00px;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: 100;
}
article .title a {
  text-decoration: none;
  color: #404040;
  font-weight: 100;
}
article .title a:hover {
  color: #007998;
}
article .post-meta {
  color: #666666;
  margin-bottom: 20px;
  font-size: 14px;
}
article .post-meta a {
  color: #888888;
  text-decoration: none;
}
article .post-meta a:hover {
  color: rgba(136, 136, 136, 0.5);
}
article .the-content a {
  font-weight: 600;
  text-decoration: none;
}
article .the-content p {
  color: #444444;
  margin-bottom: 1.1em;
}
article .the-content img {
  width: 100%;
  height: auto;
}
article .meta {
  line-height: 14.8px;
  font-size: 10px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .9px;
}
article .meta div {
  float: left;
}
article .meta .post-categories {
  list-style: none;
  margin: 0 0 10px 0;
}
article .meta .post-categories li {
  display: inline-block;
  margin-right: 10px;
}
article div.card {
  font-size:0.8em;
}
article div.card span.post-meta{
  font-size:0.8em;
}
/* Page loop styling */
.page article {
  border-bottom: none;
}
.page article .title {
  margin-bottom: 40px;
}
/* Pagination */
#pagination {
  margin-bottom: 40px;
  width: 100%;
}
#pagination .past-page {
  float: right;
}
#pagination .next-page {
  float: left;
}
/* Comments */
h3#comments {
  margin-bottom: 20px;
  font-size: 16px;
}
.comments-title {
  margin-bottom:20px;
}
.comments-area {
  padding-top: 60px;
  border-top: 1px solid #dddddd;
  margin-top: 90px;
}
.comment-list {
  list-style: none;
  margin: 0;
}
.comment-list li {
  margin-bottom: 20px;
}

.comment-list .comment {
  margin: 0 0 40px 50px;
  padding: 20px;
  position: relative;
  list-style: none;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.comment-list .comment .children {
  margin-top: 40px;
}
.comment-list .comment-author {
  font-weight: bold;
  float: left;
  margin-right: 10px;
  position: relative;
}
.comment-list .comment-author img {
  position: absolute;
  left: -70px;
  border: 1px #eeeeee solid;
}
.comment-list .comment-author .says {
  display: none;
}
.comment-meta {
  font-size: 12px;
  margin-bottom: 10px;
}
.comment-subscription-form,
.comment-subscription-form {
  margin-bottom: 0;
  font-size: 14px;
}
#respond {
  margin-bottom: 80px;
}
#respond > h3 {
  font-size: 16px;
  margin-bottom: 20px;
}
#respond form label {
  color: #888888;
}
footer {
  text-align: center;
  padding-bottom: 40px;
  font-size: 12px;
}
/* Misc & mixens
-------------------------------------------------------------- */
/* Jetpack Gallery */.gallery .gallery-item {
  margin-top: 0 !important;
}
.gallery img {
  border: none !important;
}
.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.clear {
  clear: both;
}
/* clear floats */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.clearfix {
  display: inline-block;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}
/* Media Queries for responsivenessssssss
-------------------------------------------------------------- */
@media (max-width: 767px) {
  header {
    padding: 0;
  }
  header .gravatar {
    float: none;
  }
  header hgroup {
    width: 100%;
  }
}

/* Ron custom styles */
blockquote > p {
  line-height: 1.4;
  text-align: center;
  font-size: 32px;
  margin: 48px;
  border: none;
  padding: 0;
  font-family: "Noto Serif",Georgia,Cambria,"Times New Roman",Times,serif;
  letter-spacing: .01rem;
  font-weight: 400;
  font-style: italic;
}

.code {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

code, kbd, tt, var {
	color: #666666;
	background-color: #eeeeee;
}

/**
 * Site footer
 */
 // Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;
$spacing-unit:     30px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// .site-footer {
//     @extend .container;
// }

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  margin-left: -$spacing-unit / 2;
  //@extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  text-align: left;
  color: #666666;
  font-size: 0.8em;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
    text-align: center;
  }
}